@font-face {
    font-family: opensansRegular;
    src: url('../../assets/fonts/Open_Sans/OpenSans-Regular.ttf');
}

// @font-face {
//     font-family: ralewayBold;
//     src: url('../../assets/fonts/Raleway/Raleway-Bold.ttf');
// }

// @font-face {
//     font-family: myFirstFont;
//     src: url(sansation_light.woff);
// }