/*--  Font Styles  --*/
@for $i from 1 through 40 {
    .f-#{$i} {
        font-size: ($i * 1px) !important;
    }
}

/*--  Padding Styles  --*/
@for $i from 1 through 40 {
    .p-#{$i} {
        padding: ($i * 1px) !important;
    }
    .pr-#{$i} {
        padding-right: ($i * 1px) !important;
    }
    .pl-#{$i} {
        padding-left: ($i * 1px) !important;
    }
    .pt-#{$i} {
        padding-top: ($i * 1px) !important;
    }
    .pb-#{$i} {
        padding-bottom: ($i * 1px) !important;
    }
}

/*--  Margin Styles  --*/
@for $i from 1 through 40 {
    .m-#{$i} {
        margin: ($i * 1px) !important;
    }
    .mr-#{$i} {
        margin-right: ($i * 1px) !important;
    }
    .ml-#{$i} {
        margin-left: ($i * 1px) !important;
    }
    .mt-#{$i} {
        margin-top: ($i * 1px) !important;
    }
    .mb-#{$i} {
        margin-bottom: ($i * 1px) !important;
    }
    
}

/*-- min-height --*/
@for $i from 1 through 55 {
    .mh-#{$i} {
        min-height: ($i * 1px) !important;
    }
}

/*-- ui card bottom placement --*/
@for $i from 1 through 100 {
    .ui-card-bottom-#{$i} {
        position: relative;
        bottom: -($i * 1px) !important;
    }
}
