.success-toaster{
    color: #fff;
}

.error-message {
    color: red;
    font-size: 13px;
    margin-left: 18px;
    margin-top: 5px;
}

.d-block {
    display: block;
}
.flex-grow-1 {
    flex-grow: 1;
}
.word-break {
    word-break: break-word;
}
.overflow-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.overflow-hidden {
    --overflow: hidden;
}
.d-flex {
    display: flex;
}
.c-pointer {
    cursor: pointer;
}
.m-0 {
    margin: 0 !important;
}
.p-0 {
    padding: 0 !important;
}
.pt-0 {
    padding-top: 0 !important;
    --padding-top: 0;
}
.pl-0 {
    padding-left: 0 !important;
}
.pr-0 {
    padding-right: 0 !important;
}
.ws-nowrap {
    white-space: nowrap;
}
.flex-column {
    flex-direction: column;
}
.secondary-text {
    color: #000000 !important;
}
.secondary-text-menu {
    color: #000000 !important;
}
.secondary-text-menu-active {
    // color: #328094 !important;
}
.bg-white {
    background-color: #fff;
}
.w-100 {
    width: 100%;
}
.w-auto {
    width: auto;
}
.h-100 {
    height: 100%;
}

.ion-select {
    --min-height : 55px;
}
.ml-auto {
    margin-left: auto;
}
.mt-0 {
    margin-top: 0;
}
.mb-0 {
    margin-bottom: 0;
}
.pb-0 {
    padding-bottom: 0 !important;
}
.fw-bold {
    font-weight: 700;
    color: #000000 !important;
}
.border-bottom {
    border-bottom: 1px solid #D1C5C5;
}
.border-top {
    border-top: 1px solid #D1C5C5;
}
.brand-color {
    color: #ED434F;
}
.wider-select {
    --min-width: 350px;
}
.d-inline-block {
    display: inline-block;
}
.mx-auto {
    margin: 0 auto;
}
.align-top {
    vertical-align: top;
}
.align-middle {
    vertical-align: middle;
}
.d-none {
    display: none;
}
.align-text-bottom {
    vertical-align: text-bottom;
}
.bg-transparent {
    background-color: transparent;
    --background: transparent;
}
.rounded-0 {
    border-radius: 0 !important;
}
.h-auto {
    height: auto;
}
.no-border {
    border :0;
    --border: 0;
    --border-color: transparent;
}
.text-decoration-none {
    text-decoration: none;
}
.flex-wrap {
    flex-wrap: wrap;
}
.flex-nowrap {
    flex-wrap: nowrap;
}
.fw-less-bold {
    font-weight: 600;
}

.d-contents {
    display: contents;
}
