
@import './assets/scss/styles.scss';

.table-heading{
    font-weight: bold;
    // padding: 15px 15px 15px 15px;
    background: white;
}
.table-row{
    border-bottom: solid 1px #ccc;
    border-left: solid 1px #ccc;
    border-right: solid 1px #ccc;
}
.table-row:first-child{
    border-top: solid 1px #ccc !important;
}

.ion-text-success{
    color : var(--ion-color-success);
}
.pe-none{
    pointer-events: none;
}
.cursor-pointer{
    cursor: pointer;
}
ion-row.card {
    background-color: #fff;
    border-radius: 12px;
    height: calc(70vh);
}

body {
    -webkit-user-select: none; /* Safari */ 
    -ms-user-select: none; /* IE 10 and IE 11 */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    user-select: none; /* Standard syntax */
}
