html,
body {
    font-family: 'opensansRegular',serif;
}

.forgot-password-modal {
    --max-height: 340px;
    ion-footer {
        padding: 10px 10px;
        text-align: right;
    }
}
ion-modal {
    --width: 600px;
    --height: 500px;
}
.empty-selectbox-msg {
    .alert-radio-icon {
        display: none;
    }
}
.show-overflow {
    .ion-page {
        overflow: visible;
    }
}
.menu-link {
    text-decoration: none;
    color: #000;
}
// .application-downloaded-popover {
//     --width: 300px;
// }
.download-popup-toaster {
    color: #000;
    --width: 500px;
    --background: #fff;
}
.chrome-downloaded-popup-toaster {
    // --background: #fff;
    --end: right;
    left: 20px;
    // color: #000;
    // .toast-wrapper {
    //     background-color: red;
    // }
}
.page-title {
    color: #33566e;
}
.show-modal-background {
    background-color: var(--ion-backdrop-color, rgba(0,0,0,0.2));
}
.ngx-pagination .current {
    // background: linear-gradient(to right, #E64154, #C62D71) !important;
    border-radius: 100px;
}
// .firefox-downloaded-popup-toaster {
    // --background: #fff;
    // --end: left;
    // --start: right;
    // left: 20px;
    // color: #000;
    // --width: 500px;
    // .toast-wrapper {
    //     background-color: red;
    // }
// }
.list-unstyled {
    list-style-type: none;
    padding: 0;
}

.toaster-message {
    // --max-width: 400px;
    --start: right;
}

ion-spinner {
    position: fixed;
    transform: scale(2.5);
 }
.browser-location-img {
  display: block;
  max-width: 400px;
  height: 90px;
  width: 100%;
}
 .tooltip-popover {
    ion-content {
        // --background : red;
        word-wrap: break-word;
        --background: rgba(0,0,0,.75);
        border-radius: 2px;
         --color: #fff;
        font-size: 14px;
    }
}

.content-wrapper {
    background-color: #fff;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
    border-radius: 6px;
}
.ng2-pdf-viewer-container {
    overflow-x: hidden !important;
    border-bottom: 1px solid #ccc;
}
.custom-table {
    overflow: auto;
    @media (max-width: 991px) {
        .table-header,
        .table-body {
            width: 800px;
        }
    }
    .table-body {
        max-height: 250px;
        overflow: auto;
        ion-row {
            min-height: 54px;
        }
    }
    .table-data-item {
        border-bottom: 0.3px solid rgba(196, 196, 196, 0.63);;
    }
    .table-data-column {
        display: flex;
        font-size: 15px;
        align-items: center;
    }
}
.edit-icon {
    color: #AF207F;
}
.delete-icon {
    color: #EE444C;
}
.ellipses-text {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.table-cell {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width: 767.99px) {
    .ion-padding-xs-none {
        padding: 0 !important;
    }
    .ion-margin-xs-none {
        margin: 0 !important;
    }
    .flex-small-wrap {
        flex-wrap: wrap;
    }
}
@media (min-width: 991px) {
    .d-sm-flex {
        display: flex;
    }
    .table-cell{
        padding: 15px 15px 15px 15px;
    }
    .table-heading{
        // font-weight: bold;
        padding: 15px 15px 15px 15px;
        // background: white;
    }
}
@media only screen and (min-width: 320px) and (max-width: 580px) {
    // .table-cell {
    //     display: unset;
    // }
    .ngx-pagination {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .ngx-pagination li {
        width: max-content !important;
    }
}
.ngx-pagination {
    padding-left: unset !important;
}
.ow-anywhere {
    overflow-wrap: anywhere;
}
ion-tab-bar {
    border-bottom: none;
}

.flat-card {
    box-shadow: none;
    border: 1px solid rgba(217, 217, 217, 1);
}

.title-video-frame {
    border-radius: 6px;
}

