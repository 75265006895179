:root {
  --ion-color-primary: #33566e;
  --ion-color-primary-rgb: 238, 68, 76;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #6688A2;
  --ion-color-primary-tint: #6688A2;

  --ion-color-secondary: #328094;
  --ion-color-secondary-rgb: 175, 32, 127;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #9a1c70;
  --ion-color-secondary-tint: #b7368c;

  --ion-color-tertiary: #333333;
  --ion-color-tertiary-rgb: 51, 51, 51;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #2d2d2d;
  --ion-color-tertiary-tint: #474747;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-columbia-blue: #D0E7ED;
  --ion-color-munsell-blue: #328094;
  --ion-color-slate-gray: #657795;

  --ion-font-family : 'opensansRegular',serif;
  --ion-blue-bg:#2C93AE;
  --ion-blue-txt:#0992B8;
  --ion-gray-bg: #E4E9F2;
  --ion-gray-txt:#AAACB4;
// mobile theme colors
--ion-color-primary-black: #101426;
--ion-color-primary-grey: #8F9BB3;
--ion-color-button-bg: #E9F9FF;


}

@media (prefers-color-scheme: darkssmode) {
  /*
  * Dark Colors
  * -------------------------------------------
  */

  body {
     --ion-color-primary: #33566e;
     --ion-color-primary-rgb: 238, 68, 76;
     --ion-color-primary-contrast: #ffffff;
     --ion-color-primary-contrast-rgb: 255, 255, 255;
     --ion-color-primary-shade: #6688A2;
     --ion-color-primary-tint: #6688A2;

     --ion-color-secondary: #328094;
     --ion-color-secondary-rgb: 175, 32, 127;
     --ion-color-secondary-contrast: #ffffff;
     --ion-color-secondary-contrast-rgb: 255, 255, 255;
     --ion-color-secondary-shade: #328094;
     --ion-color-secondary-tint: #328094;

     --ion-color-tertiary: #333333;
     --ion-color-tertiary-rgb: 51, 51, 51;
     --ion-color-tertiary-contrast: #ffffff;
     --ion-color-tertiary-contrast-rgb: 255, 255, 255;
     --ion-color-tertiary-shade: #2d2d2d;
     --ion-color-tertiary-tint: #474747;

     --ion-color-success: #2dd36f;
     --ion-color-success-rgb: 45, 211, 111;
     --ion-color-success-contrast: #000000;
     --ion-color-success-contrast-rgb: 0, 0, 0;
     --ion-color-success-shade: #28ba62;
     --ion-color-success-tint: #42d77d;

     --ion-color-warning: #ffc409;
     --ion-color-warning-rgb: 255, 196, 9;
     --ion-color-warning-contrast: #000000;
     --ion-color-warning-contrast-rgb: 0, 0, 0;
     --ion-color-warning-shade: #e0ac08;
     --ion-color-warning-tint: #ffca22;

     --ion-color-danger: #eb445a;
     --ion-color-danger-rgb: 235, 68, 90;
     --ion-color-danger-contrast: #ffffff;
     --ion-color-danger-contrast-rgb: 255, 255, 255;
     --ion-color-danger-shade: #cf3c4f;
     --ion-color-danger-tint: #ed576b;

     --ion-color-medium: #92949c;
     --ion-color-medium-rgb: 146, 148, 156;
     --ion-color-medium-contrast: #000000;
     --ion-color-medium-contrast-rgb: 0, 0, 0;
     --ion-color-medium-shade: #808289;
     --ion-color-medium-tint: #9d9fa6;

     --ion-color-light: #f4f5f8;
     --ion-color-light-rgb: 244, 245, 248;
     --ion-color-light-contrast: #000000;
     --ion-color-light-contrast-rgb: 0, 0, 0;
     --ion-color-light-shade: #d7d8da;
     --ion-color-light-tint: #f5f6f9;
  }

  /*
  * iOS Dark Theme
  * -------------------------------------------
  */

  .ios body {
     --ion-background-color: #000000;
     --ion-background-color-rgb: 0, 0, 0;

     --ion-text-color: #ffffff;
     --ion-text-color-rgb: 255, 255, 255;

     --ion-color-step-50: #0d0d0d;
     --ion-color-step-100: #1a1a1a;
     --ion-color-step-150: #262626;
     --ion-color-step-200: #333333;
     --ion-color-step-250: #404040;
     --ion-color-step-300: #4d4d4d;
     --ion-color-step-350: #595959;
     --ion-color-step-400: #666666;
     --ion-color-step-450: #737373;
     --ion-color-step-500: #808080;
     --ion-color-step-550: #8c8c8c;
     --ion-color-step-600: #999999;
     --ion-color-step-650: #a6a6a6;
     --ion-color-step-700: #b3b3b3;
     --ion-color-step-750: #bfbfbf;
     --ion-color-step-800: #cccccc;
     --ion-color-step-850: #d9d9d9;
     --ion-color-step-900: #e6e6e6;
     --ion-color-step-950: #faf4f9;

     --ion-item-background: #000000;

     --ion-card-background: #1c1c1d;
  }
}
  //.hide-on-large
  .header{
    // background: #0C334D !important;
  }
  .grayText {
    color: #9F9FA0 !important;
  }
@media (min-width: 768px) {
  
  // .hide-on-small {
  //   display: block;
  
  // }
  .hide-on-large {   
    display: none !important;
  }
  
 
  ion-menu {
    position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      z-index: 9;
  }

  ion-menu:hover:not(.fixed-sidebar) {

    --side-max-width: 250px;
    --side-min-width: 250px;
    transition: all 0.3s ease-in-out;;
    .brand-logo {
      display: none;
    }
    ion-content {
      --overflow: hidden;
    }
  }
  ion-menu.fixed-sidebar {
    --side-max-width: 250px;
    --side-min-width: 250px;
    position: relative;
    .brand-logo {
      display: none;
    }
  }
  ion-menu:not(:hover):not(.fixed-sidebar){
    transition: all 0.3s ease-in-out;
    --side-max-width: 80px;
    --side-min-width: 80px;
    .menu__logo,
    .menu-toggle-btn {
      display: none;
    }
    .logo-wrapper {
      --margin-bottom: 15px !important;
      margin-bottom: 15px !important;
    }
    // ion-menu {
      ion-row {
        justify-content: center;
        max-width: 40px !important;
        border-radius: 6px;
        height: 42px;
        padding: 0 !important;
        ion-label {
          display: none;
          // transform: scale(0);
        }
        &:not(.active) {
          background-color: inherit;
        }
      }
      .sidebar-list-item {
        --padding-top: 15px !important;
        padding-top: 8px !important;
      }
      .menu {
        border-radius: 50% !important;
      }
    // }
}

}

@media (max-width: 768px) {
  .hide-on-small {   
    display: none !important;
  }
  .hide-on-large {   
    // display: block;
  }
  .margin-left-right {
    margin : 0 18px !important;
  }
 
  ion-menu {
    --side-max-width: 260px;
    --side-min-width: 260px;
    --width: 260px;
  }
  .menu-toggle-btn,
  .brand-logo {
    display: none;
  }

}
