/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
// @import url('./assets/fonts/Open_Sans/OpenSans-Regular.ttf');

$primary-color: #000;
html,
body,
ion-content {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    -ms-overflow-style: none !important;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    /* Ensures smooth scrolling on iOS */
    height: 100%;
}

.heading,
.alert-title {
    font-family: 'Raleway', sans-serif;
}

// .primary__input {
//     border: 1.5px solid #C3E5EE;
//     border-radius: 5px;
// }


.primary__input .floating-label {
    height: auto;  /* Adjust to show when there is content */
    transition: height 0.2s ease-in-out;  /* Smooth transition for height */  
    border: 1.5px solid !important;
    border-radius: 5px; 
  }
  
  /* When the label is empty, set its height to 0 */
  .primary__input .floating-label:not(.floating-label) {
    height: 0;
    opacity: 0;
    visibility: hidden;
    padding: 0;
    transition: height 0.2s ease-in-out;
  }
.text-danger {
    color: #C62D71;
}

.flex {
    display: flex;
}

.cursor {
    cursor: pointer;
}

.disabled-elm {
    cursor: not-allowed;
}

.alert-radio-group::-webkit-scrollbar {
    width: 0.8em;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 0.5px solid slategrey;
}

.primary__button {
    // background-image: linear-gradient(to right, #E64154, #C62D71);
    // --background: linear-gradient(to right, #E64154, #C62D71);
    background: #33566e !important;
    --background: #33566e !important;
    // width: 100%;
    // max-width: max-content;
    padding: 0.9rem;
    color: white;
    border-radius: 5px;
    min-width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;
    font-size: 15px;
    min-height: 44px;
}

.w-100 {
    width: 100% !important;
}

.primary__button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

.copilot__button {
    background: #fff !important;
    --background: #fff !important;
    border: #328094 2px solid !important;
    color: #328094 !important;
    --color: #328094 !important;
    border-radius: 5px;
    font-size: 15px;
    min-height: 44px;
}

.copilot__chat_button {
    background: #F0F4F9 !important;
    --background: #F0F4F9 !important;
    border: #F0F4F9 2px solid !important;
    color: #328094 !important;
    --color: #328094 !important;
    border-radius: 5px;
    font-size: 15px;
    min-height: 44px;
    --box-shadow: none !important;

    ::part(native) {
        --box-shadow: none !important;
    }
}

.copilot-img {
    width: 30px;
}


@media (max-width: 768px) {
    .copilot__button {
        width: 40vw;
        /* Width relative to viewport width on mobile */
        min-height: 36px;
        /* Reduced height for mobile */
        font-size: 12px;
        /* Smaller font size for mobile */
        padding: 0px 0px;
        /* Reduced padding for mobile */
        border-radius: 4px;
        /* Slightly less rounded corners */
        color: #328094 !important;
        --color: #328094 !important;
    }
    .copilot__button button-native {
        color: #328094 !important;
        --color: #328094 !important;
    }
 
      ion-button.actionBut {
        background-color: #fff !important;
        --background: #fff !important;
        color: #328094;
        .button-native {
            padding: 50px;
        }
      }

    .copilot-img {
        width: 5.3vw;
        height: 5.3vw;
        margin-right: 1.38vw;
    }
}

.primary__border__button {

    background-color: transparent;
    // width: 100%;
    padding: 0.9rem;
    color: #33566e;
    border-radius: 5px;
    min-height: 44px;
    font-size: 15px;

    &.bordered {
        border: 1.5px solid #33566e;
    }

    &.gray-button {
        border: 1.5px solid #8B8E8F;
        // color: #8f8383;
    }
}

.primary-heading {
    color: #328094;
    font-weight: 600;
}

.text-primary {
    color: #1F384C;
}

.text-secondary {
    color: #328094;
}

.text-secondary-2 {
    color: #2C93AE;
}

.text-black {
    color: #000;
}

.link:hover {
    border-bottom: 1px solid #33566e;
    cursor: pointer;
}

.rounded-button {
    --border-radius: 20px;
}

.text-success {
    color: green;
}


// ion-menu {
//     max-width: 25%!important;
// }

// .hide-on-small {
//     display: none !important;

//     // @media screen and (min-width: 768px) {
//     //     display: block !important;
//     // }
// }

.hide-on-medium {
    display: block !important;

    @media screen and (min-width: 768px) {
        display: none !important;
    }
}

@media only screen and (min-width: 361px) and (max-width: 580px) {
    .mobile-button {
        margin-left: -12%;
    }
}

.heading {
    font-size: 1.5rem;
    font-weight: 500;
    display: block;
}

.sub-heading {
    font-size: 1rem;
    font-weight: 500;
    display: block;
}

.legacy-container {
    // height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.legacy-description {
    font-size: 16px;
}

.layout-container {
    $primary-color: rgb(175, 175, 175);

    .relative_container {
        position: relative;
    }

    .flex-col {
        display: flex;
        flex-direction: column;
    }

    .agreement-form {
        --min-height: 90%;
        --max-width: 800px;
        --width: 100%;
    }

    .menu__logo {
        width: 70%;
        padding: 0.5rem;
        margin: 1rem 0;
    }

    .boldText {
        font-size: 25px;
        color: #928a8a;
    }


    .menu {
        text-align: start;
        display: flex;
        text-align: start;
        flex-wrap: nowrap;
        align-items: center;
        padding: 0.1rem 0rem;
        // width: 70%;
        max-width: 195px;
        border-radius: 5px;
        gap: 15px;
        margin: 0 auto;
        background: transparent !important;

        &:hover {
            // background-color: #eee;
            cursor: pointer;
        }
    }

    .sidebar-wrapper {
        --overflow: auto !important;
    }

    .sidebar-list-item {
        scrollbar-width: none !important;
    }

    .sidebar-wrapper,
    .sidebar-list-item,
    .footer-container,
    .header-wrapper,
    .logo-wrapper {
        background: #eff6fc;
        --background: #eff6fc;
        // .list-md {
        //     background: #F5F5F5;
        // }
        
    }

    .sidebar-list-item {
        --padding-top: 0 !important;
        padding-top: 0 !important;
        --padding-bottom: 0 !important;
        padding-bottom: 0 !important;
        
    }

    .ion-page {
        // background-color: #f6f9fc;
        background-color: white ;
    }

    .info {
        color: black;
        // font-weight: 600;
        // font-family: 'Raleway', sans-serif;
    }

    .inactive-img {
        display: block;
    }

    .sidebar-shown {
        @media (min-width: 768px) {
            margin-left: 80px;
        }
    }

    .active-img {
        display: none;
    }

    .menu-indicator {
        position: absolute;
        left: 0;
        top: 20%;
        background-image: linear-gradient(#e84055, #c52c70);
        height: 60%;
        width: 7px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .vertical_center {
        position: absolute;
        top: 10%;

        @media screen and (max-width: 768) {}
    }

    .menu-indicator {
        display: none;
    }

    .active {

        // background-image: linear-gradient(to right, #e84055, #c52c70);
        .menu-indicator {
            display: block;
        }

        ion-icon,
        ion-label,
        label,
        a {
            color: #328094;
        }

        .inactive-img {
            display: none;
        }

        .active-img {
            display: block;
        }
    }

    // .mobile-logo {
    //     max-width: 9vw;
    //     max-width: 50
    // }

    .sidebar-bottom-items {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: var(--ion-item-background, var(--ion-background-color, #fff));
    }

    .header {
        height: 90px;
        display: flex;
        background-color: #f6f9fc;
        align-items: center;
        // border-bottom: 1px solid #8B8E8F;
        ;

        // ion-toolbar {
        //     --background: #f6f9fc;
        // }

        &:after {
            display: none;
        }

        .welcome-text {
            padding: 0.4rem 0.6rem;
        }
    }

    .paragraph {
        font-size: 0.9rem;
        font-weight: bold;
    }

    .search-input {
        border: 1px solid #b8b6b7;
        border-radius: 20px;
    }

    .user-avatar {
        border: 1px solid #33566e;
        height: 40px;
        width: 40px;
        object-fit: contain;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: #33566e;

        &.notifications {
            display: flex;
            position: relative;
            box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.2);
        }

        .notifications-count {
            background: #33566e;
            border-radius: 50%;
            font-size: 7px;
            text-align: center;
            width: 13px;
            position: absolute;
            right: 0;
            top: 0;
            color: #fff;
            padding: 2px;
        }
    }

    @media (max-width: 768px) {
        // .user-avatar {
        //     box-sizing: border-box;
        //     border: 1px solid #fff;
        //     height: 40px;
        //     width: 40px;
        //     object-fit: contain;
        //     align-items: center;
        //     justify-content: center;
        //     border-radius: 50%;
        //     color: #fff;

        //     &.notifications {
        //         // display: flex;
        //         position: relative;
        //         box-shadow: 1px 1px 8px -1px rgba(0, 0, 0, 0.2);
        //     }

        //     .notifications-count {
        //         background: #33566e;
        //         border-radius: 50%;
        //         font-size: 7px;
        //         text-align: center;
        //         width: 13px;
        //         position: absolute;
        //         right: 0;
        //         top: 0;
        //         color: #fff;
        //         padding: 2px;
        //     }
        // }

    }

    .border-none {
        border: none !important;
    }

    .unread-alert {
        --background: rgb(245, 245, 245);
    }

    .crypto-link {
        text-decoration: underline;
        color: #33566e;
    }

    .content-wrapper {
        // --background: url(/assets/images/background-image.png);
        // background-color: #f6f9fc;
        margin-top: 15px;
        background-color: #fff;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
    }

    .activate-account-msg {
        // width: 100%;
        background: #E0F6FF;
        color: #9F9FA0;
        text-align: center;
        padding: 0.5rem 1rem;
        border-radius: 6px;
    }

    .notifications-popover {
        --width: 510px;
        --max-width: 100%;
    }

    /*hide the inputs/checkmarks and submenu*/
    input,
    ul.submenu {
        display: none;
    }

    /*position the label*/
    label {
        position: relative;
        display: block;
        cursor: pointer;
    }

    /*show the submenu when input is checked*/
    input:checked~ul.submenu {
        display: block;
    }

    .content {
        margin-top: 5rem;
    }

    // .passwordset-form {
    //     --height: 420px;
    // }

    .notifications-list {
        ion-item:not(.no-data) {
            position: relative;
            padding-left: 1rem;

            &:before {
                content: '';
                position: absolute;
                width: 5px;
                height: 5px;
                background-color: #000;
                border-radius: 50%;
                z-index: 9;
                top: 50%;
                left: 12px;
            }
        }
    }

    div.item-native {
        &:hover {
            background: rgb(245, 245, 245) !important;
            cursor: pointer !important;
        }
    }

    .active-img-container {
        // background: #0F4366 !important;
    }

    .active-menu {
        background: rgba(225, 235, 243, 1);
    }

    .img-container {
        // border-radius: 5px;
        // background: #fff;
        border-radius: 50%;
    }

    .app-running {
        color: white;
        font-weight: bold;
        margin-right: 2rem;
        background-color: #06B050;
        border-radius: 5px;
        padding: 2px 10px 2px 10px;
    }

    .app-installed {
        color: white;
        font-weight: bold;
        margin-right: 2rem;
        background-color: #FF0000;
        border-radius: 5px;
        padding: 2px 10px 2px 10px;
    }

    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background: black;
        opacity: 0.8;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
    }

    .brand-logo {
        width: 40px;
        height: auto;
        margin: auto;
    }

    .sidebar-wrapper .sidebar-list-item .menu .img-container {
        width: 36px;
        /* Reduce the width of the icon container */
        height: 36px;
        /* Reduce the height of the icon container */
    }

    .sidebar-wrapper .sidebar-list-item .menu .img-container img {
        width: 100%;
        /* Ensure the icon fits the container */
        height: 100%;
        /* Ensure the icon fits the container */
    }

    .sidebar-wrapper .sidebar-list-item .menu .secondary-text-menu {
        font-size: 15px;
        /* Reduce the font size */
    }

    .sidebar-wrapper .sidebar-list-item .submenu a {
        font-size: 15px;
        /* Reduce the font size for submenu items */
    }

    .notifications .ion-icon {
        font-size: 20px;
        /* Reduce the font size of the notifications icon */
    }

    .secondary-text-menu.reduced-font-size {
        font-size: 15px;
        /* Additional class for reducing font size */
    }

    .h-60 {
        height: 60px;
    }

    .flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .font-small {
        font-size: 14px !important;
    }


}

.bold-text {
    font-weight: bold;
}

.text-accent {
    color: #EC3390 !important;
}

.text-center {
    text-align: center;
}

.hide-with-anim {
    height: auto;
    max-height: 100px;
    /* Set the maximum height for the visible state */
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
    opacity: 1;
}

.divider {
    width: 90%;
    border: 0.5px solid #cdcdcd;
    margin: 1rem auto;
}

.content-divider {
    margin: 1rem 0;
    border: 0.5px solid #A9A9A9;
}

.rotate-down {
    transform: rotate(90deg);
}

.hide-with-anim.hide {
    max-height: 0;
    opacity: 0;
}

.height-max-content {
    min-height: max-content !important;
    padding-bottom: 5vh;
}

.no-scrollbar {

    /* Hide scrollbar for Chrome, Safari, and Edge */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE and Edge (earlier versions) */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

// toaster

.custom-toast {

    --background: #ffffff;
    --color: #000000;
    --border-radius: 8px;
    --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 350px;
    transition: top 0.3s ease-in-out;
}

.custom-toast.success {
    --background: #dff0d8;
    --color: #3c763d;
}

.custom-toast.error {
    --background: #f2dede;
    --color: #a94442;
}


.ag-header-container {
    background-color: #d0e7ed !important;
    /* Replace with your desired color */
    color: white;
    /* Change text color if needed */
}

@media (min-width: 768px) {
    
    // ion-toolbar {
    //     --background: #f6f9fc;
    // }
}
ion-toolbar#test {
    --ion-toolbar-background: #f6f9fc !important;
  }

@media (max-width: 768px) {
    // ion-toolbar#test {
    //     --ion-toolbar-background: #0C334D !important;
    //   }
    .app_feat_icon {
        // display: none !important;
    }
    .layout-container .ion-page {
        // bottom: 8% !important;
    }
    .layout-container .content-wrapper {
        background: none !important;
    }
    .primary__button {
        background: #E9F9FF !important;
        color: black;
        border-radius: 20px;
        border: 1px solid #2C93AE;
    }
    .primary__border__button {
        border-radius: 20px;
    }
    ::ng-deep ion-checkbox.mySecrete.md {
        --background: #e0e0e0 !important;
    }
    ::ng-deep ion-checkbox.mySecrete .checkbox-icon {
        color: #ff5733 !important; /* Checkmark color */
      }
      ::ng-deep ion-checkbox.mySecrete {
        --checkmark-color: #ff5733; /* Replace with your desired color */
      }

}

// :host(.button-solid.in-toolbar:not(.ion-color):not(.in-toolbar-color)) .button-native  {
//     color: #328094 !important;
//     --color: #328094 !important;
//     border:1px solid red!important;
// }

::ng-deep ion-button.copilot__button .button-native {
    color: var(--ion-toolbar-background, var(--color)) !important;
}
// ::ng-deep ion-modal.custom-modal {
//     .modal-wrapper {
//         // border:5px solid red !important;
//       background: rgba(0, 0, 0, 0.5) !important; /* Set the background color */
//       border-radius: 16px 16px 0 0; /* Optional rounded corners */
//     }
//   }
// //   ::ng-deep ion-modal.show-modal.mobileAdd .modal-wrapper {
//     --background: none !important; /* Removes background */
//     border: 1px solid rgb(255, 94, 0) !important; /* Sets a border */
// }

  ion-modal.custom-modal::part(wrapper),
  ion-modal.show-modal.mobileAdd::part(wrapper),
  ion-modal.show-modal.finalizePopup::part(wrapper)
  ion-modal.show-modal.confirmation-popup.mobile::part(wrapper)
   {
    background-color: none !important;
  }
  
  ion-modal.custom-modal,
  ion-modal.show-modal.mobileAdd,
  ion-modal.finalizePopup,
  ion-modal.edit-guardian-popup.mobile,
  ion-modal.show-modal.confirmation-popup.mobile {
    --background: none !important;
    background: none !important;
  }
  
  ion-modal::part(backdrop) {
    // background: none !important;
  }
  :host {
    ion-item.noBg {
      --background: #fff !important;
      --color: #333;
      
    }
  }
  


@media only screen and (pointer: coarse) and (orientation: landscape) {
    body {
      transform: none !important;
      transform-origin: initial !important;
      width: 100% !important;
      height: auto !important;
      position: static !important;
      top: auto !important;
      left: auto !important;
    }
  }
